// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-campanhas-jsx": () => import("./../../../src/pages/campanhas.jsx" /* webpackChunkName: "component---src-pages-campanhas-jsx" */),
  "component---src-pages-clientes-jsx": () => import("./../../../src/pages/clientes.jsx" /* webpackChunkName: "component---src-pages-clientes-jsx" */),
  "component---src-pages-fornecedores-jsx": () => import("./../../../src/pages/fornecedores.jsx" /* webpackChunkName: "component---src-pages-fornecedores-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-participar-jsx": () => import("./../../../src/pages/participar.jsx" /* webpackChunkName: "component---src-pages-participar-jsx" */),
  "component---src-pages-produtos-jsx": () => import("./../../../src/pages/produtos.jsx" /* webpackChunkName: "component---src-pages-produtos-jsx" */),
  "component---src-pages-sobre-jsx": () => import("./../../../src/pages/sobre.jsx" /* webpackChunkName: "component---src-pages-sobre-jsx" */),
  "component---src-pages-supplier-jsx": () => import("./../../../src/pages/supplier.jsx" /* webpackChunkName: "component---src-pages-supplier-jsx" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

